@keyframes dropIn {
  0% {
    translate: 0 -20px;
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    translate: 0 0;
    opacity: 1;
  }
}

h1 {
  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
  color: #080707;
  padding-bottom: 24px;
  animation: dropIn 1s ease-in-out;
}
