.hero-textbox {
  background-color: rgba(245, 249, 250, 0.7);
  grid-column-start: span 6;
  margin-bottom: 20px;
  padding: 30px;
  /* transform-origin: 0 0;
  transform: scaleX(1) translateZ(0);
  transition: transform 1s cubic-bezier(0.17, 0, 0.1, 1) 0.333s; */
  animation: boxGrows 0.7s ease-out;
}

/* .hero-textbox::before {
  transform-origin: 0 0;
  transform: scaleX(1) translateZ(0);
  transition: transform 1s cubic-bezier(0.17, 0, 0.1, 1) 0.333s;
} */

@keyframes boxGrows {
  0% {
    transform-origin: bottom left;
    transform: scaleX(0) scaleY(0);
  }
  100% {
    transform-origin: bottom left;
    transform: scaleX(1) scaleY(1);
  }
}
