@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

p {
  font-family: Proza Libre;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #080707;
  padding-bottom: 16px;

  animation: fadeIn 1s ease-in-out;
}
